const environment = {
  production: {
    APP_API_URL: 'https://numbers.school.iq/api',
    APP_PATH: '/',
  },
  development: {
    APP_API_URL: 'http://localhost:8040/api',
    APP_PATH: '/',
  },
};

module.exports = environment;
