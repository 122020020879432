// axios
import axios from 'axios'
import Vue from 'vue'
import { setupCache } from 'axios-cache-adapter'
import auth from '@/store/auth'
import toast from '@/store/toast'
import store from '@/store/index'
import { i18n } from '@/plugins/i18n'

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 0,
})
const axiosIns = axios.create({
  headers: {
    Accept: 'application/json',
    iid: 1,
    version: '1.0.3',
    device_id: 'web',
  },
  adapter: cache.adapter,
});
axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent
    const accessToken = auth.state.token

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`
    config.headers['Accept-Language'] = i18n.locale

    // console.log(i18n.locale)

    return config
  },
  error => {
    Promise.reject(error)
  },
);

axiosIns.interceptors.response.use(
  response => {
    if (response.data.entering_level != null) {
      store.commit('SET_ENTERING_LEVEL', response.data.entering_level)
    }

    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      auth.dispatch('logout')
    }
    toast.dispatch('error', error.response ? error.response.data.message : error.message)
    Promise.reject(error)
    throw error;
  },
);

Vue.prototype.$http = axiosIns

export default axiosIns
