import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line import/no-cycle
import axios from '@axios'
import store from '@/store/index'
// eslint-disable-next-line import/no-cycle

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    statistics: {

      new_students: 0,
      new_teachers: 0,
      new_drivers: 0,
      directorates_count: 0,
      governorates_count: 0,
      active_phones: 0,
      sent_phones: 0,
      active_schools: 0,
      unlocked_phones: 0,
      unsent_phones: 0,
      newPhones: 0,

    },
  },
  getters: {

    get: state => key => {
      const keys = key.split('.');

      return keys.reduce((a, c) => a[c], state);
    },
  },
  actions: {
    async fetchApplicationStatistics(ctx, autoRefresh = false) {
      if (store.state.loggedIn) {
        const response = await axios.get('/statistics', {
          cache: {
            maxAge: 10, // 1 minute to ms
          },
        })
        ctx.state.statistics = response.data.data;
      }
      if (autoRefresh) {
        setTimeout(() => ctx.dispatch('fetchApplicationStatistics', true), 300000)
      }
    },
  },
  mutations: {
  },
  modules: {
  },
})
