import {
  mdiAccountGroupOutline,
  mdiHomeOutline,
  mdiMapMarkerRadius,
  mdiLicense,
  mdiAccountSupervisor,
  mdiTownHall,
  mdiPower,
  mdiSchool,
  mdiSend,
  mdiCloseBox,
  mdiFormatListNumbered,
  mdiSeatPassenger,
  mdiPhoneLog,
} from '@mdi/js'

export default [
  {
    subheader: 'menu.home1',
  },
  {
    title: 'menu.home',
    icon: mdiHomeOutline,
    color: 'info',
    to: 'home',
  },
  {
    subheader: 'menu.navigation',
  },
  {
    title: 'menu.governorates',
    icon: mdiMapMarkerRadius,
    statistics: 'statistics.governorates_count',
    color: '#34eb49',
    to: 'governorates.index',
    badgeColor: '#34eb49',
  },

  {
    title: 'menu.directorates',
    icon: mdiLicense,
    statistics: 'statistics.directorates_count',
    color: '#34eb49',
    to: 'all_directorates.index',
    badgeColor: '#34eb49',
  },

  {
    title: 'menu.schools',
    icon: mdiTownHall,
    statistics: 'statistics.schools_count',
    color: '#34eb49',
    to: 'all_schools.index',
    badgeColor: '#34eb49',

  },

  {
    title: 'menu.teachers',
    icon: mdiSchool,
    color: '#34eb49',
    children: [
      {
        title: 'menu.newNumbers',
        to: 'new_teachers_numbers.index',
        statistics: 'statistics.new_teachers',
        color: 'info',
        icon: mdiPhoneLog,
        badgeColor: 'info',
      },

      {
        title: 'menu.view',
        to: 'all_teachers.index',
        color: '#34eb49',
      },

    ],
  },

  {
    title: 'menu.students',
    icon: mdiAccountSupervisor,
    color: '#34eb49',
    children: [

      {
        title: 'menu.newNumbers',
        to: 'new_students_numbers.index',
        statistics: 'statistics.new_students',
        color: 'info',
        icon: mdiPhoneLog,
        badgeColor: 'info',
      },

      {
        title: 'menu.view',
        to: 'all_students.index',
        color: '#34eb49',
      },

    ],
  },
  {
    title: 'menu.drivers',
    icon: mdiSeatPassenger,
    color: '#34eb49',
    children: [
      {
        title: 'menu.newNumbers',
        to: 'new_drivers_numbers.index',
        statistics: 'statistics.new_drivers',
        color: 'info',
        icon: mdiPhoneLog,
        badgeColor: 'info',
      },

      {
        title: 'menu.view',
        to: 'all_drivers.index',
        color: '#34eb49',
      },

    ],
  },

  {
    title: 'menu.fast',
    icon: mdiFormatListNumbered,
    color: '#34abeb',
    to: 'numbers.fast',
  },

  {
    subheader: 'menu.numbers',
  },
  {
    title: 'menu.sentNumbers',
    icon: mdiSend,
    color: 'success',
    to: 'sent.numbers',
    statistics: 'statistics.sent_phones',
    badgeColor: 'success',
  },
  // {
  //   title: 'menu.unsentNumbers',
  //   icon: mdiSendLock,
  //   color: 'error',
  //   to: 'unsent.numbers',
  //   statistics: 'statistics.unsent_phones',
  //   badgeColor: 'error',
  // },

  {
    title: 'menu.activeNumbers',
    icon: mdiPower,
    color: 'success',
    to: 'active.numbers',
    statistics: 'statistics.active_phones',
    badgeColor: 'success',
  },

  // {
  //   title: 'menu.inactiveNumbers',
  //   icon: mdiPowerOff,
  //   color: 'error',
  //   to: 'inactive.numbers',
  //   statistics: 'statistics.inactive_phones',
  //   badgeColor: 'error',
  // },

  {
    title: 'menu.lockedNumbers',
    icon: mdiCloseBox,
    color: 'error',
    to: 'locked.numbers',
    statistics: 'statistics.locked_phones',
    badgeColor: 'error',
  },

  {
    subheader: 'menu.permissions',
  },
  {
    title: 'menu.users',
    icon: mdiAccountGroupOutline,
    color: 'info',
    children: [
      {
        title: 'menu.view_users',
        to: 'users.index',
        color: 'info',
      },
      {
        title: 'menu.roles',
        to: 'roles.index',
        color: 'info',
      },

      {
        title: 'menu.permissions',
        to: 'permissions.index',
        color: 'info',
      },
    ],
  },

  // {
  //   title: 'menu.site.settings',
  //   to: 'site.settings',
  //   color: 'error',
  //   class: 'spin',
  //   icon: mdiCog,
  // },

]
