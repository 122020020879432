import rolesStore from '@/store/roles'
import toast from '@/store/toast'
import { i18n } from '@/plugins/i18n'

const checkPermission = permissions => {
  if (!permissions) {
    return false;
  }
  if (typeof permissions === 'string') {
    permissions = [permissions];
  }

  return rolesStore.state.permissions.some(permission => permissions.findIndex(perm => perm === permission.name) !== -1)
}
const checkRole = roles => {
  if (!roles) {
    return false;
  }
  if (typeof roles === 'string') {
    roles = [roles];
  }

  return roles.some(role => role === rolesStore.state.role.name)
}
const ASPlugin = {
  install(Vue, options) {
    // functions
    Vue.prototype.$can = perm => checkPermission(perm);
    Vue.prototype.$canRole = perm => checkRole(perm);

    // filters
    Vue.filter('format_date', value => new Date(value).toLocaleDateString('ar-EG-u-nu-latn', {
      weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
    }));
    Vue.filter('short_string', (value, length = 18) => value.substring(0, length) + (value.length > length ? '...' : ''));

    // directives
    Vue.directive('can', (el, binding) => {
      const perm = binding.value;
      const canAccess = binding.modifiers.role ? checkRole(perm) : checkPermission(perm);
      if (!canAccess && el.parentNode) {
        if (binding.modifiers.disable) {
          el.addEventListener('click', event => {
            event.stopImmediatePropagation();
            toast.dispatch('error', i18n.t('no_permission'))
              .then()

            return false;
          }, { capture: true });
        } else {
          el.parentNode.removeChild(el);
        }
      }
    });
    Vue.directive('focusNextOnEnter', {
      inserted(el, binding, vnode) {
        el.addEventListener('keyup', ev => {
          const inputElements = Array.from(vnode.elm.querySelectorAll('input:not([disabled]):not([readonly])'))
          const index = inputElements.indexOf(ev.target)

          if (ev.keyCode === 13 && index < inputElements.length - 1) {
            inputElements[index + 1].focus();
          }
        });
      },
    });
    Vue.directive('max-length', {
      bind: (el, binding, vnode) => {
        const maxChars = binding.value
        const handler = e => {
          if (e.target.value && e.target.value.length > maxChars) {
            e.target.value = e.target.value.substr(0, maxChars)
            vnode.elm.dispatchEvent(new CustomEvent('input')); // added this
          }
        }
        el.addEventListener('input', handler)
      },
    });
  },
};

export default ASPlugin;
