import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import auth from '@/store/auth'

// import { Role } from '../_helpers/roles'

// eslint-disable-next-line import/no-cycle
import useRole from '@/plugins/useRole';

import { i18n } from '@/plugins/i18n'
import store from '@/store'
import statistics from '@/store/statistics'

const { canAccess } = useRole()
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/authentication/login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/pages/profile/index.vue'),
    meta: {
      layout: 'content',
    },
  },

  /// Begin User Routes
  {
    path: '/users',
    name: 'users.index',
    component: () => import('@/views/pages/users/index.vue'),
    meta: {
      layout: 'content',
      permission: 'users.index',
    },
  },

  /// Begin Roles Routes
  {
    path: '/roles',
    name: 'roles.index',
    component: () => import('@/views/pages/roles-permissions/roles/index.vue'),
    meta: {
      layout: 'content',
    },
  },

  /// Begin Permission Routes
  {
    path: '/permissions',
    name: 'permissions.index',
    component: () => import('@/views/pages/roles-permissions/permissions/index.vue'),
    meta: {
      layout: 'content',
    },
  },

  /// Begin Governorates Routes
  {
    path: '/governorates',
    name: 'governorates.index',
    component: () => import('@/views/pages/governorates/index.vue'),
    meta: {
      layout: 'content',
      type: 'governorates',
      permission: 'governorates.index',
    },
  },

  /// Begin Directorates Routes
  {
    path: '/all_directorates',
    name: 'all_directorates.index',
    component: () => import('@/views/pages/directorates/index.vue'),
    meta: {
      layout: 'content',
      type: 'all_directorates',
      permission: 'directorates.index',
    },
  },

  {
    path: '/directorates/:id',
    name: 'directorates.index',
    component: () => import('@/views/pages/directorates/index.vue'),
    meta: {
      layout: 'content',
      type: 'directorates',
      permission: 'directorates.index',
    },
  },

  /// Begin Parents Routes
  {
    path: '/all_teachers',
    name: 'all_teachers.index',
    component: () => import('@/views/pages/teachers/index.vue'),
    meta: {
      layout: 'content',
      type: 'all_teachers',
    },
  },

  {
    path: '/teachers/:id',
    name: 'teachers.index',
    component: () => import('@/views/pages/teachers/index.vue'),
    meta: {
      layout: 'content',
      type: 'teachers',
    },
  },

  {
    path: '/all_students',
    name: 'all_students.index',
    component: () => import('@/views/pages/students/index.vue'),
    meta: {
      layout: 'content',
      type: 'all_students',
    },
  },
  {
    path: '/all_drivers',
    name: 'all_drivers.index',
    component: () => import('@/views/pages/drivers/index.vue'),
    meta: {
      layout: 'content',
      type: 'all_drivers',
    },
  },

  {
    path: '/drivers/:id',
    name: 'drivers.index',
    component: () => import('@/views/pages/drivers/index.vue'),
    meta: {
      layout: 'content',
      type: 'drivers',
    },
  },

  {
    path: '/newStudentsNumbers',
    name: 'new_students_numbers.index',
    component: () => import('@/views/pages/students/newNumbers.vue'),
    meta: {
      layout: 'content',
      type: 'newStudentsNumbers',
    },
  },

  {
    path: '/students/:id',
    name: 'students.index',
    component: () => import('@/views/pages/students/index.vue'),
    meta: {
      layout: 'content',
      type: 'students',
    },
  },


  {
    path: '/numbers/fast',
    name: 'numbers.fast',
    component: () => import('@/views/pages/numbers/fast.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/newTeachersNumbers',
    name: 'new_teachers_numbers.index',
    component: () => import('@/views/pages/teachers/newNumbers.vue'),
    meta: {
      layout: 'content',
      type: 'newTeachersNumbers',
    },
  },

  {
    path: '/newDriversNumbers',
    name: 'new_drivers_numbers.index',
    component: () => import('@/views/pages/drivers/newNumbers.vue'),
    meta: {
      layout: 'content',
      type: 'newDriversNumbers',
    },
  },

  /// Begin Teachers Routes
  {
    path: '/all_teachers',
    name: 'all_teachers.index',
    component: () => import('@/views/pages/students/index.vue'),
    meta: {
      layout: 'content',
      type: 'all_teachers',
    },
  },

  /// Begin Schools Routes
  {
    path: '/schools_dir/:id',
    name: 'schools.index.dir',
    component: () => import('@/views/pages/schools/index.vue'),
    meta: {
      layout: 'content',
      type: 'schools_dir',
      permission: 'schools.index',
    },
  },

  {
    path: '/schools_gov/:id',
    name: 'schools.index.gov',
    component: () => import('@/views/pages/schools/index.vue'),
    meta: {
      layout: 'content',
      type: 'schools_gov',
      permission: 'schools.index',
    },
  },

  {
    path: '/all_schools',
    name: 'all_schools.index',
    component: () => import('@/views/pages/schools/index.vue'),
    meta: {
      layout: 'content',
      type: 'all_schools',
      permission: 'schools.index',
    },
  },

  /// Begin Numbers Routes

  {
    path: '/sent/numbers',
    name: 'sent.numbers',
    component: () => import('@/views/pages/numbers/index.vue'),
    meta: {
      layout: 'content',
      type: 'sentNumbers',
    },
  },
  {
    path: '/unsent/numbers',
    name: 'unsent.numbers',
    component: () => import('@/views/pages/numbers/index.vue'),
    meta: {
      layout: 'content',
      type: 'unsentNumbers',
    },
  },
  {
    path: '/active/numbers',
    name: 'active.numbers',
    component: () => import('@/views/pages/numbers/index.vue'),
    meta: {
      layout: 'content',
      type: 'activeNumbers',
    },
  },
  {
    path: '/inactive/numbers',
    name: 'inactive.numbers',
    component: () => import('@/views/pages/numbers/index.vue'),
    meta: {
      layout: 'content',
      type: 'inactiveNumbers',
    },
  },
  {
    path: '/locked/numbers',
    name: 'locked.numbers',
    component: () => import('@/views/pages/numbers/index.vue'),
    meta: {
      layout: 'content',
      type: 'lockedNumbers',
    },
  },
  {
    path: '/unlocked/numbers',
    name: 'unlocked.numbers',
    component: () => import('@/views/pages/numbers/index.vue'),
    meta: {
      layout: 'content',
      type: 'unlockedNumbers',
    },
  },

  {
    path: '/parent/numbers/:id',
    name: 'parent.numbers.index',
    component: () => import('@/views/pages/numbers/index.vue'),
    meta: {
      layout: 'content',
      type: 'parent',
      permission: 'students.index',
    },
  },

  {
    path: '/parent/numbers/:id',
    name: 'parent.numbers.index',
    component: () => import('@/views/pages/numbers/index.vue'),
    meta: {
      layout: 'content',
      type: 'parent',
      permission: 'students.index',
    },
  },

  {
    path: '/inactive/numbers',
    name: 'inactive.numbers.index',
    component: () => import('@/views/pages/numbers/index.vue'),
    meta: {
      layout: 'content',
      type: 'inactive',
      permission: 'inactive.index',
    },
  },
  {
    path: '/inactive/numbers/:id',
    name: 'inactive.numbers.index',
    component: () => import('@/views/pages/numbers/index.vue'),
    meta: {
      layout: 'content',
      type: 'inactive',
      permission: 'inactive.index',
    },
  },

  {
    path: '/active/numbers',
    name: 'active.numbers.index',
    component: () => import('@/views/pages/numbers/index.vue'),
    meta: {
      layout: 'content',
      type: 'active',
      permission: 'activated.index',
    },
  },

  {
    path: 'active/numbers/:id',
    name: 'parent.numbers.index',
    component: () => import('@/views/pages/numbers/index.vue'),
    meta: {
      layout: 'content',
      type: 'active',
      permission: 'activated.index',
    },
  },

  {
    path: '/restriction/numbers',
    name: 'restriction.numbers.index',
    component: () => import('@/views/pages/numbers/index.vue'),
    meta: {
      layout: 'content',
      type: 'restriction',
      permission: 'restricted.index',
    },
  },

  {
    path: '*',
    name: 'error-404',
    component: () => import('@/views/pages/errors/404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/403',
    name: 'error-403',
    component: () => import('@/views/pages/errors/403.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})
router.beforeEach(async (to, from, next) => {
  if (to.name !== from.name) {
    store.state.pageTitle = null;
  }

  if (to.path === '/') {
    return next({ name: 'home' });
  }

  const { isAuthenticated } = auth.getters;

  const canAccessRoute = canAccess(to.name)
  if (!auth.state.loaded && isAuthenticated) {
    await auth.dispatch('getUser');
  }
  if (!canAccessRoute) {
    return next({ name: '403' })
  }
  if ((isAuthenticated && to.name === 'login')) {
    return next({ name: 'error-403' })
  }

  if (!isAuthenticated && to.name !== 'login') {
    return next({ name: 'login' })
  }

  return next()
})

const currentRouteName = to => {
  if (to.meta.name) {
    switch (typeof to.meta.name) {
      case 'string':
        return to.meta.name;
      case 'function':
        return to.meta.name(to)
      default:
        return '';
    }
  }

  return to.name;
};
router.afterEach(to => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = ` ادارة الارقام - ${i18n.t(`router.${currentRouteName(to)}`).toString()}`
  });
});
export default router
