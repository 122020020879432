<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <h3 v-if="!$vuetify.breakpoint.mobile">
            {{ currentRouteName }}
          </h3>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <app-bar-i18n></app-bar-i18n>
          <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span> &copy; {{ new Date().getFullYear() }} <a
          href="https://kf.iq/"
          class="text-decoration-none"
        >
          مركز الكفيل
        </a>
        </span>
        <div class="align-center d-none d-md-flex">
          <!--          <span>  ادارة مركز الكفيل </span>-->
          <!--          <v-icon-->
          <!--            color="error"-->
          <!--            class="ms-2"-->
          <!--          >-->
          <!--            {{ icons.mdiHeartOutline }}-->
          <!--          </v-icon>-->
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n'

// App Bar Components
// import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'

import { mdiMenu, mdiHeartOutline } from '@mdi/js'

import { getVuetify } from '@core/utils'

// Search Data

import { ref, watch } from '@vue/composition-api'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer'
import appBarSearchData from '@/assets/app-bar-search-data'
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import navMenuItems from '@/navigation/vertical'
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import useRole from '@/plugins/useRole'
import store from '@/store/index'

export default {
  components: {
    LayoutContentVerticalNav,
    // eslint-disable-next-line vue/no-unused-components
    AppCustomizer,
    AppBarI18n,

    // App Bar Components

    // eslint-disable-next-line vue/no-unused-components
    AppBarThemeSwitcher,
    AppBarUserMenu,
  },
  computed: {
    currentRouteName() {
      if (store.state.pageTitle) {
        return store.state.pageTitle;
      }
      let { name } = this.$route;
      if (this.$route.meta.name) {
        switch (typeof this.$route.meta.name) {
          case 'string':
            name = this.$route.meta.name;
            break;
          case 'function':
            name = this.$route.meta.name(this.$route);
            break;
          default:
            name = '';
        }
      }

      return this.$t(`router.${name}`);
    },
    entering_color() {
      switch (store.state.entering_level) {
        case 3:
          return 'error';
        case 2:
          return 'warning';
        case 1:
          return 'primary';
        default:
          return 'success';
      }
    },
  },
  setup() {
    const $vuetify = getVuetify()

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    // NOTE: Update search function according to your usage
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }
    const { canAccess } = useRole();

    return {
      navMenuItems: navMenuItems.map(item => {
        if (item.children) {
          item.children = item.children.filter(child => child.subheader || canAccess(child.to))
        }

        return item;
      }).filter(item => item.subheader || canAccess(item.to)),
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,
      store,
      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
